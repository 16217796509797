// app/components/TabMenu.tsx

import { NavLink, useNavigate, useNavigation } from "@remix-run/react";
import React, { useEffect, useState } from "react";
import { To } from "react-router";

interface TabMenuProps {
  tabs: string[];
}


const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export const TabMenu: React.FC<TabMenuProps> = ({ tabs }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const navigation = useNavigation();

  const handleNavigation = (path: To) => {
    setLoading(true);
    navigate(path);
  };

  useEffect(() => {
    if (navigation.state === 'idle') {
      setLoading(false);
    }
  }, [navigation.state]);
  return (
    <>
      {loading && <Loader />} {/* Show loader if loading is true */}
      <div className={"tab-menu"}>
        {tabs.map((tab) => (
          <NavLink
            key={tab}
            className={`tab-item`}
            to={tab === "Fonts" ? "/" : "/foundries/"}
            onClick={(e) => {
              e.preventDefault();
              handleNavigation(tab === "Fonts" ? "/" : "/foundries/");
            }}
          >
            {tab}
          </NavLink>
        ))}
      </div>
    </>
  );
};
